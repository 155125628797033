import React, { StrictMode } from 'react';
import * as ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import { Helmet } from 'react-helmet';
import { PayPalScriptProvider } from '@paypal/react-paypal-js';

const container = document.getElementById('root');
const root = ReactDOM.createRoot(container);
const meta = [
  {
    name: 'description',
    content:
      `Entdecken Sie hochwertige, nachhaltige Kindermode aus EU-Produktion bei soova.at. Unsere Kleidung ist umweltfreundlich, fair gehandelt und aus qualitativ hochwertigen Materialien hergestellt.`.toString(),
  },
];

root.render(
  <StrictMode>
    <BrowserRouter>
      <Helmet>
        <script async src="//www.instagram.com/embed.js"></script>
        <meta
          name="description"
          content="Entdecken Sie hochwertige, nachhaltige Kindermode aus EU-Produktion bei soova.at. Unsere Kleidung ist umweltfreundlich, fair gehandelt und aus qualitativ hochwertigen Materialien hergestellt."
        />
      </Helmet>
      <PayPalScriptProvider
        options={{
          'client-id':
            'Ab7CkZJAItHwqYzgM84ESTw0ALjsB1cCJi-3LcKareL5f52FFMROY1Onb-5eqEmoMAEKfTKb9UCqOzhp',
          currency: 'EUR',
        }}
      >
        <App />
      </PayPalScriptProvider>
    </BrowserRouter>
  </StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
