import { ChakraProvider, Link } from '@chakra-ui/react';
import React, { useState, useEffect, useRef, lazy, Suspense } from 'react';
import { Routes, Route } from 'react-router-dom';
import { BrowserRouter, Navigate } from 'react-router-dom';
import { modeArrays } from './components/Molecules/Atoms/NavArrays';
import Axios from 'axios';
import './App.css';
import secureLocalStorage from 'react-secure-storage';

const Homepage = lazy(() => import('./components/Homepage'));
const ProductPage = lazy(() => import('./components/ProductPage'));
const Sustainability = lazy(() => import('./components/Sustainability'));
const ProductDetails = lazy(() => import('./components/ProductDetails'));
const Checkout = lazy(() => import('./components/Checkout'));
const AboutUs = lazy(() => import('./components/AboutUs'));
const Production = lazy(() => import('./components/Production'));
const AGB = lazy(() => import('./components/AGB'));
const DataProtection = lazy(() => import('./components/DataProtection'));
const Blogs = lazy(() => import('./components/Blogs'));

function App() {
  const routerArray = [...modeArrays[0], ...modeArrays[1], ...modeArrays[2]];
  const [type, setType] = useState('Bodys');
  const [agegroup, setAgegroup] = useState('Kinder');
  const [product, setProduct] = useState('Kinder');
  const [size, setSize] = useState('');
  localStorage.setItem('login', JSON.stringify(false));
  const [collArray, setCollArray] = useState([]);
  const [cartArray, setCartArray] = useState();

  function changeProduct(prod) {
    return (
      setProduct(prod) +
      setTimeout(() => {
        return window.scrollTo({
          top: prodText.current.offsetTop || '930',
          behavior: 'smooth',
        });
      }, 100)
    );
  }
  const setKids = () => {
    return changeProduct('Kinder');
  };
  const setPure = () => {
    return changeProduct('Soova-Pur');
  };
  const setAdults = () => {
    return changeProduct('Erwachsene');
  };
  const [allProds, setAllProds] = useState([]);

  const [orderInfo, setOrderInfo] = useState(
    JSON.parse(secureLocalStorage.getItem('orderInfo'))
  );

  useEffect(() => {
    let result = [];
    let Obj = {
      ö: '%C3%B6',
      ä: '%C3%A4',
    };
    Axios.get(`https://soova-backend.herokuapp.com/cms/product`).then(
      response => {
        response.data.map(res => {
          if (
            res.description.split(',')[0].includes('ö') ||
            res.description.split(',')[0].includes('ä')
          ) {
            result.push(res);
            result.push({
              description: res.description.replace(/ö|ä/gi, function (matched) {
                return Obj[matched];
              }),
              _id: res._id,
              name: res.name,
              collections: res.collections,
              quantity: res.quantity,
              price: res.price,
              material: res.material,
              season: res.season,
              size: res.size,
              public: res.public,
              orderId: res.orderId,
              category: res.category,
              sale: res.sale,
            });
          } else {
            result.push(res);
          }
        });
        return setAllProds(result);
      }
    );
  }, []);

  useEffect(() => {
    Axios.get(`https://soova-backend.herokuapp.com/cms/collections`).then(
      function (response) {
        setCollArray(response.data);
      }
    );
  }, []);

  useEffect(() => {
    setCartArray(JSON.parse(localStorage.getItem('cartArray')));
  }, []);
  const prodText = useRef();

  return (
    <ChakraProvider>
      <Suspense
        fallback={
          <div
            style={{
              width: '100vw',
              height: '100vh',
              backgroundColor: '#F0F4E7',
            }}
          >
            Loading...
          </div>
        }
      >
        <Routes>
          {routerArray.map(route => {
            return (
              <Route
                path={`/${'product'}/${route.id}/${route.content}`}
                element={
                  <ProductPage
                    key={window.location.pathname}
                    type={type}
                    agegroup={agegroup}
                    setSize={setSize}
                    agegroupfun={() => changeProduct(agegroup)}
                  />
                }
              />
            );
          })}
          <Route
            path=""
            element={
              <Homepage
                prodText={prodText}
                setCardArray={setCartArray}
                setType={setType}
                setAgegroup={setAgegroup}
                setKids={setKids}
                setPure={setPure}
                setProduct={setProduct}
                setAdults={setAdults}
                product={product}
              />
            }
          />
          <Route
            path="/product"
            element={
              <ProductPage
                type={type}
                agegroup={agegroup}
                agegroupfun={() => changeProduct(agegroup)}
              />
            }
          />
          <Route path="/production" element={<Production />} />
          <Route path="/blogs" element={<Blogs />} />
          <Route
            path="/product/Gutschein"
            element={
              <ProductDetails mode={'Gutschein'} setCardArray={setCartArray} />
            }
          />
          <Route path="/Datenschutz" element={<DataProtection />} />
          <Route path="/AGBs" element={<AGB />} />
          <Route path={'/aboutUs'} element={<AboutUs />} />
          <Route path={'/sustainability'} element={<Sustainability />} />
          <Route
            key={cartArray}
            path={'/Kassa'}
            element={cartArray ? <Checkout /> : <Navigate to="/" />}
          />
          {allProds.map(prod => {
            return (
              <>
                <Route
                  path={`${prod.category}/${prod.description.split(',')[0]}/${
                    prod.name
                  }`}
                  element={
                    <ProductDetails
                      setCardArray={setCartArray}
                      sale={prod.sale}
                    />
                  }
                />
                <Route
                  path={`Kollektionen/${prod.collections}/${prod.name}`}
                  element={
                    <ProductDetails
                      setCardArray={setCartArray}
                      who={prod.category}
                      sale={prod.sale}
                    />
                  }
                />
              </>
            );
          })}
          {collArray.map(coll => {
            return (
              <Route
                path={`/Kollektionen/${coll.name}`}
                element={
                  <ProductPage
                    type={type}
                    agegroup={agegroup}
                    setSize={setSize}
                    agegroupfun={() => changeProduct(agegroup)}
                  />
                }
              />
            );
          })}
          ;
        </Routes>
      </Suspense>
    </ChakraProvider>
  );
}

export default App;
