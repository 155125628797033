import Axios from "axios";
import { useEffect, useState } from "react";

const kids = []
const adults = []
const pure = []

Axios.get('https://soova-backend.herokuapp.com/cms/productType').then((response)=>{
response.data.map((type)=>{

  let Obj = {
    ö: "%C3%B6",
    ä: "%C3%A4",
};

  if(type.category == 'Kinder' && type.published){
    if(type.type.includes('ö') || type.type.includes('ä')){

      kids.push({content:type.type,
        onClick:'onClick',
        id: type.category})
        kids.push({content:type.type.replace(/ö|ä/gi, function (matched) {
          return Obj[matched];
      }),
          onClick:'onClick',
          id: type.category})
    } else

    {
      kids.push({content:type.type,
        onClick:'onClick',
        id: type.category})
    }

  }
  if(type.category == 'Erwachsene' && type.published) {
    if(type.type.includes('ö' || 'ä')){
      adults.push({content:type.type,
        onClick:'onClick',
        id: type.category})
        adults.push({content:type.type.replace(/ö|ä/gi, function (matched) {
          return Obj[matched];
      }),
          onClick:'onClick',
          id: type.category})
    } else{
      adults.push({content:type.type,
        onClick:'onClick',
        id: type.category})
    }

  }
  if(type.category == 'Soova-Pur' && type.published){
    if(type.type.includes('ö' || 'ä')){
      pure.push({content:type.type,
        onClick:'onClick',
        id: type.category})
        pure.push({content:type.type.replace(/ö|ä/gi, function (matched) {
          return Obj[matched];
      }),
          onClick:'onClick',
          id: type.category})
    } else{
      pure.push({
        content:type.type,
        onClick:'onClick',
        id: type.category
      })
    }
  } 
  

})
})

export const modeArrays = [kids, adults, pure ]
